









































import {Component, Vue} from 'vue-property-decorator';
import {UserStore} from '@/store/modules/user';
import {collectionURLs} from '@/utils/config';

@Component
export default class BookmarkList extends Vue {

  loading: boolean = false;
  popover: any = {};
  bookmarks: any[] = [];

  edit(collection: string, id: string) {
    this.$router.push(`/${collectionURLs[collection]}/edit/${id}`);
  }

  async unBookmark(id: string) {
    this.popover[id] = false;
    await UserStore.removeBookmark(id);
    this.bookmarks = Object.entries(UserStore.bookmarks).map(([key, value]) => value);
  }

  created() {
    this.bookmarks = Object.entries(UserStore.bookmarks).map(([key, value]) => value);
  }

}
